// $platform-name: 'rspcawa';

//
// Environment
//

//
// Config values
//
$platform-name: "rspcawa";
$assets-path: "../assets/";

//
// Colours
//
$brand-primary: #199ed5;
$brand-secondary: #73c167;

$donate-colour: $brand-secondary;
$bg: #faf7f4;
$headings-colour: #58595b;
$grey: $headings-colour;
// Create greyscale

// Utility colours

//
// Layout
//

// Breakpoints
$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1200px
);

// Spacers
$spacer: 1rem;
$spacer-y: $spacer;
$spacer-x: $spacer;
$site-bleed: 20px;
$container-max-width: 1230px;
//
// Misc
//
$body-colour: $grey;
//
// Transitions
//

//
// Logo
//
$logo: "logo.svg";
$logo-width: 300px;
$logo-height: 120px;
// $logo-absolute: true;
//
// Typography
//
$font-open-sans: "Open Sans", sans-serif;
$font-family-base: $font-open-sans;
$headings-font-family: "Lato", sans-serif;

$font-weight-bold: 900;

// Links

// Icon font

//
// Buttons
//
$border-radius: 3px;
$btn-border-radius: $border-radius;
$btn-padding-x: 1.25em;
$btn-padding-y: 0.75em;
$btn-font-family: $font-open-sans;
$btn-font-weight: $font-weight-bold;
// Donate button
$donate-btn-colour: white;
//
// Social icons
// Documentation: https://fresco-docs.netlify.com/#/header/social-icons/
//
$social-icons-border-radius: 100px;
$social-icons-width: 30px;
$social-icons-height: 30px;
$social-icons-font-size: 1rem;

$social-icons-background-colour: #989898;
$social-icons-colour: #fff;
// Social network colours - use these by changing $social-icons-use-brand-colours to true

// Header specific

// Footer specific social icons
$social-icons-footer-background-colour: transparent;
//
// Share this page
//

//
// Form elements
//

// Field groups

//
// Tables
//

//
// Card
//
$card-border-radius: $border-radius;
$card-heading-colour: $headings-colour;
$card-hover-image-opacity: 1;
$card-hover-image-scale: 1.1;
$card-hover-details-background-colour: $brand-primary;
$card-hover-heading-colour: white;
$card-border: 0;
// Card text overlay

// Card text over

// Card side by side

// Card hover state

// Event cards

//
// Menu admin
//
$menu-admin-enabled: false;
//
// Header
//

// Header content

$header-content-margin-bottom: 0.5rem;

// Tagline
$tagline-enabled: true;
$tagline-font-size: 1em;
$tagline-breakpoint: map-get($breakpoints, md);
// Main call to action
$cta-btn-gap: 10px;

// Search
$header-search-margin-right: 5px;

// Search - input
$header-search-input-max-width: 0;
$header-search-input-margin-right: 0;
$header-search-input-padding: 0px 0px;
$header-search-input-border-colour: #fff;
$header-search-input-margin-right: 0px;

// Search - button
$header-search-button-background-colour: #989898;
$header-search-button-icon-colour: #fff;
$header-search-button-border-radius: 100px;
$header-search-button-width: 30px;
$header-search-button-height: 30px;
// Search when in nav

// Social icons

//
// Sticky header
//

//
// Navigation
//
$nav-background-colour: transparent;
$nav-normal-align-items: center;
$nav-top-level-item-colour: $headings-colour;
// Top level items
$nav-top-level-item-padding: 15px 20px;
$nav-top-level-item-font-weight: 900;
// Submenus

// Burger button

// Nav normal
$nav-normal-margin-bottom: 0;
//
// Specifically when no banner present and you have gone for $header-absolute: true
//

//
// Carousel
//
$carousel-heading-font-size: 3rem;
$carousel-max-width: 100%;
$carousel-contents-max-width: $container-max-width;
$carousel-image-overlay: linear-gradient(
  to right,
  rgba(0, 0, 0, 0.7) 0%,
  rgba(0, 0, 0, 0) 50%
);
$carousel-details-position-y: center;
$carousel-details-background-colour: transparent;
$carousel-details-box-shadow: none;
$carousel-details-max-width: 600px;
$carousel-heading-colour: #fff;
$carousel-summary-colour: #fff;

$carousel-controls-position-y: center;
$carousel-controls-position-x: split;
$carousel-controls-margin-x: $site-bleed;
$carousel-controls-icon-colour: #fff;
$carousel-read-more-enabled: false;
// $carousel-summary-enabled: false;
// Carousel controls (left-right buttons)

// Carousel buttons (dots or tabs)

// Carousel buttons dots

// Carousel buttons tabs

// Carousel video play button (before breakpoint, when carousel details present)

// Carousel scroll button

// Carousel after breakpoint

// Banner min-height under sm breakpoint - helps to keep some vertical height at narrow viewports, especially for letter-box aspect ratios

//
// Home intro
//
$home-intro-background-colour: $bg;
$home-intro-font-size: 1rem;
$home-intro-padding-y: $spacer * 3;
$home-intro-margin-top: 0;
//
// Home features
//
$home-features-padding-top: 0;
$home-features-background-colour: $bg;
//
// Impact stats
//
$impact-stats-background-colour: #f9fafc;
$impact-stats-background-image: "impact.jpg";
$impact-stats-background-image-opacity: 1;
$impact-stats-text-align: left;
$impact-stats-breakpoint: 900px;

// Heading
$impact-stats-heading-text-align: left;
// Individual stat

// Figure
$impact-stats-figure-font-family: $font-open-sans;
$impact-stats-figure-colour: $brand-primary;
// Summary

//
// Home feeds
//
$home-feeds-background-colour: $bg;
$home-feeds-padding-bottom: $spacer * 3;
// Feeds title

// Feed item

// Event feed items
$feed-item-event-start-date-enabled: false;
$feed-item-event-location-enabled: false;
// Twitter feed

//
// Footer
//
$footer-boxes-count: 4;
$footer-prefab: 5;
$footer-padding-y: $spacer * 3;
// $footer-background-colour: rgba(#eceeef, .3);
$footer-background-colour: $brand-primary;
$footer-colour: white;
$footer-link-colour: white;

// Footer admin links
// $footer-admin-basket-link-enabled: true;
// Newsletter
$newsletter-use-placeholders: true; // Turn on to use placeholders in the inputs rather than labels
$newsletter-background-colour: transparent;
$newsletter-colour: #fff;
$newsletter-heading-text-align: left; // left, center, right
$newsletter-heading-font-size: 1.5em;
$newsletter-heading-colour: white;
$newsletter-heading-margin-bottom: $spacer * 1.5;
$newsletter-button-height: 46px; // Hard code this so you can match it with the inputs
$newsletter-button-colour: white;
// Newsletter hero - full width form above rest of footer

//
// Context container
//

// Donors list

//
// Posts
//

//
// Header text (container for breadcrumb and page title)
//

//
// Page title
//
$page-title-margin-bottom: $spacer * 2;
//
// Sidebar
//

// Associated lists

//
// Listing
//

// Listed post settings

//
// Breadcrumb
//

//
// Blockquote
//

//
// Fundraising
//

// Find a fundraiser widget

// Top fundraisers

//
// Donation form
//

//
// Donation form
//

// $home-quick-giving-type: overlay;
// $home-quick-giving-background-colour: rgba(#000, 0.7);
// $home-quick-giving-type-overlay-gradient: linear-gradient(
//   to left,
//   rgba(0, 0, 0, 0) 40%,
//   rgba(0, 0, 0, 1) 100%
// );
//
// Home quick giving panel
//
// $home-quick-giving-heading-colour: white;
// $home-quick-giving-donate-btn-background-colour: $donate-colour;
// $home-quick-giving-donation-amount-background-colour: rgba(#000, 0.4);
// $home-quick-giving-donate-btn-colour: $grey;
//
// Home quick giving panel
//
// $home-quick-giving-donate-btn-background-colour: $donate-colour;
// $home-quick-giving-donate-btn-colour: white;

$quick-giving-background-colour: $brand-primary;
$home-quick-giving-donate-btn-background-colour: $donate-colour;
$home-quick-giving-donate-btn-colour: white;
$home-quick-giving-donation-amount-background-colour: lighten(
  $brand-primary,
  20%
);
$home-quick-giving-heading-colour: white;
$home-quick-giving-donation-amount-figure-colour: white;
$home-quick-giving-padding-y: $spacer * 3;
// Adjacent homepage quick giving

// Overlay homepage quick giving

//
// Totalisers
//

//
// Comments
//

//
// Blog details
//
$blog-details-enabled: false;

//
// FAQs
//

//
// Shop
//

// Departments list

// Product-price

// Product post

// Subsite
$subsite-enabled: true;
$subsite-hide-main-header: false;
$subsite-hide-main-tagline: true;
$subsite-hide-header-search: false;
$subsite-hide-main-cta: false;
$subsite-content-max-width: $container-max-width;
// $subsite-title-font-size: $page-title-font-size !default;

// Subsite logo
$subsite-logo:  'title';
$subsite-logo-width: auto;
$subsite-logo-height: auto;
$subsite-logo-margin: $spacer 0 0;

// Subsite nav
// $subsite-nav-breakpoint: $nav-breakpoint !default;
// $subsite-nav-max-width: $nav-normal-max-width !default;
// $subsite-nav-margin-top: $spacer !default;
// $subsite-nav-margin-bottom: $spacer !default;
// $subsite-nav-background-colour: $nav-background-colour !default;
// $subsite-nav-contents-max-width: $container-max-width !default;
// $subsite-nav-align-items: $nav-normal-align-items !default; // left, center, right
// $subsite-nav-top-level-item-padding: $nav-top-level-item-padding !default;
// $subsite-nav-top-level-item-colour: $nav-top-level-item-colour !default;
// $subsite-nav-top-level-item-font-family: $nav-top-level-item-font-family !default;
// $subsite-nav-top-level-item-font-size: $nav-top-level-item-font-size !default;
// $subsite-nav-top-level-item-font-weight: $nav-top-level-item-font-weight !default;
// $subsite-nav-top-level-item-background-colour: $nav-top-level-item-background-colour !default;
// $subsite-nav-top-level-item-hover-colour: $nav-top-level-item-hover-colour !default;
// $subsite-nav-top-level-item-hover-background-colour: $nav-top-level-item-hover-background-colour !default;

// $subsite-nav-submenu-width: $nav-submenu-width !default;
// $subsite-nav-submenu-background-colour: $nav-submenu-background-colour !default;
// $subsite-nav-submenu-box-shadow: $nav-submenu-box-shadow !default;
// $subsite-nav-submenu-border-radius: $nav-submenu-border-radius !default;
// $subsite-nav-submenu-item-padding: $nav-submenu-item-padding !default;
// $subsite-nav-submenu-item-colour: $nav-submenu-item-colour !default;
// $subsite-nav-submenu-item-font-family: $nav-submenu-item-font-family !default;
// $subsite-nav-submenu-item-font-size: $nav-submenu-item-font-size !default;
// $subsite-nav-submenu-item-font-weight: $nav-submenu-item-font-weight !default;
// $subsite-nav-submenu-item-hover-colour: $nav-submenu-item-hover-colour !default;
// $subsite-nav-submenu-item-hover-background-colour: $nav-submenu-item-hover-background-colour !default;


//
// Cookie consent
//
