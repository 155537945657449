//Center the tagline in the header
.pageHeader .headerContent .tagline {
  justify-content: center;
  display: flex;
  // align-items: flex-start;
  &:last-child {
    /* or `:nth-child(n + 4)` */
    order: 1;
  }
  span {
    color: $brand-primary;
  }
}

//icon on donate buttons
.mainCallToActionButtons > a.cta-button,
.cta-button.donate,
.button.donate,
button.donate {
  padding: 0.75em 1em 0.75em 1.25em;
  &::after {
    content: "\f054";
    font-family: "FontAwesome";
    margin-left: 8px;
  }
}

.homefeaturecategory-homeboxquickgiving .quickGivingPanel .donateBtn {
  // padding: 0.75em 1em 0.75em 1.25em;
  &::after {
    content: "\f054";
    font-family: "FontAwesome";
    margin-left: 8px;
  }
}

//Adding a line to the top of the menu
.menuMain .topLevel {
  border-top: solid 1px #e0e0e0;
}

//aligning impact header
.homefeaturecategory-homeboximpactstats .homeImpactWrapper > h2 {
  margin-left: 20px;
}

@media (max-width: map-get($breakpoints, md)) {
  .homefeaturecategory-homeboximpactstats tr {
    text-align: center;
  }

  .homefeaturecategory-homeboximpactstats .homeImpactWrapper > h2 {
    margin-left: 0px;
    text-align: center;
  }
}
@media (max-width: map-get($breakpoints, sm)) {
  .homefeaturecategory-homeboximpactstats:before {
    background-size: contain;
    background-position: bottom;
    opacity: 1;
  }
}
//adding icon to quick giving header
.homefeaturecategory-homeboxquickgiving h2 {
  position: relative;
  &:before {
    content: " ";
    background-image: url(../assets/paw.png);
    background-repeat: no-repeat;
    display: block;
    height: 50px;
    width: 50px;
    position: absolute;
    left: -40px;
    background-size: 60%;
    background-position: 0px 5px;
  }
}
@media (max-width: map-get($breakpoints, lg)) {
  .homefeaturecategory-homeboxquickgiving h2 {
    display: inline-block;
  }
}
// feedbox2 padding - I couldn't see a variable that targetted just feedbox2
section.homeFeed.homeFeedBox2 {
  padding-top: 2rem;
}

// Footer
.footerBox {
  h3 {
    font-size: 1.5em;
  }

  .charityNumber {
    font-size: 0.875em;
  }

  .socialIcons {
    margin-left: -12px;
    flex-wrap: wrap;
  }
}

.footerBox.footerBox2 {
  h3 {
    margin-bottom: $spacer * 1.5;
  }
}

.footerBox.footerBox3 {
  h3 {
    margin-bottom: $spacer * 1.5;
  }
}

.footerBox.footerBox4 {
  border-top: 1px solid rgba(white, 0.3);
  font-size: 12px;
  .footerLinks {
    display: flex;

    li {
      font-size: 16px;
      padding: $spacer * 2 $spacer * 1 $spacer * 2 0;
    }

    @media (max-width: map-get($breakpoints, lg)) {
      li {
        font-size: 14px;
      }
    }

    @media (max-width: map-get($breakpoints, md)) {
      display: block;
      padding: $spacer * 2 0;

      li {
        padding: 0;
        font-size: 16px;
      }
    }
  }
}

// QA
@media (max-width: map-get($breakpoints, lg)) {
  .carouselSlideDetail.appeal .button:not(.donate) {
    background-color: #55AE47;
  }
}


// Subsite tweaks
body.subsite{
  .postAside:not(.appealWidgetsWrapper):not(.eventWidgetsWrapper):not(.officeFeedWrapper) {
    display: none;
  }
}

body.subsite.animal-welfare-matters {
  
  .menuMain ul.topLevel {
    padding-top: #{$spacer * 0.25};
    padding-bottom: #{$spacer * 0.25};
  }
  
  .menuMain ul.topLevel a {
    @include button (
      $btn-background-colour: $donate-colour,
      $btn-text-colour: $white,
      $btn-hover-style: darken
    );
    margin: #{$spacer * 0.25};
    background-color: $brand-secondary;
    padding: 0.75em 1em 0.75em 1.25em;
    &::after {
      content: "\f054";
      font-family: "FontAwesome";
      margin-left: 8px;
    }
  }

  @media (min-width: #{$carousel-breakpoint + 1}) {
    .carouselSlideTitle:after {
      display: none;
    }
  }
  
  .contentContainer .headerTextSubsite {
    display: none;
  }

  .contentContainer .headerWrapper.headerWrapperSubsite {
    position: relative;
    @media (min-width: #{$carousel-breakpoint + 1}) {
      table.headerTextBody {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        margin: 0 auto;
        transform: translate(-50%, -50%);
      }
    }
    table.headerTextBody {
      width: calc(100% - #{$site-bleed * 2}) !important;
      max-width: $container-max-width;
      margin: $spacer auto 0;
      padding: 0;
      border: none;
      background-color: transparent;
      * {
        margin: 0;
        padding: 0;
        background-color: transparent;
        border: none;
      }
      p {
        color: $brand-primary;
        font-weight: bold;
        font-size: $font-size-h3;
        span {
          font-size: 1em;
          color: $brand-secondary;
        }
      }
    }
  }
}


//  Subsite tweaks
body.subsite {

  .menuMain ul.topLevel {
    padding-top: #{$spacer * 0.25};
    padding-bottom: #{$spacer * 0.25};
  }
  
  .menuMain ul.topLevel a {
    @include button (
      $btn-background-colour: $donate-colour,
      $btn-text-colour: $white,
      $btn-hover-style: darken
    );
    margin: #{$spacer * 0.25};
    background-color: $brand-secondary;
    padding: 0.75em 1em 0.75em 1.25em;
    &::after {
      content: "\f054";
      font-family: "FontAwesome";
      margin-left: 8px;
    }

    &[aria-label="Book now"] {
      @include button (
      $btn-background-colour: #FF7722,
      $btn-text-colour: $white,
      $btn-hover-style: darken
    );
    }
  }

}

// subsites with a subsite nav - these will also need to be added to js hijack nav array
body.subsite {
  &.dog-training,
  &.pet-sterilisation-program {
    .contentContainer .headerTextSubsite {
      display: none;
    }
    &.page-has-banner .menuMain .topLevel {
      border-top: none;
      padding-top: #{$spacer  *  2};
    }
  }
}

// subsites without a subsite nav
body.subsite {
  &.stop-animal-cruelty {
    .menuMain {
      display: none;
    }
  }
}

// Pet sterilisation programme subsite

body.subsite.pet-sterilisation-program {
  .menuMain ul.topLevel a {
    background-color: #92278F;
    &:hover {
      background-color: darken(#92278F, 5%);
    }
  }
}